<style scoped lang='scss'>
.BoxPadding{
  padding-top: 125px;
  padding-bottom:100px;
}
.title{font-size: $Size50;}
.index_banner{
  width:100%;
  min-width:1400px;
  min-height:335.42px;
  position: relative;
  .banner{ width:100%; }
  .banner_title1{
    text-align: center;
    position: absolute;
    font-size: $Size30;
    color: white;
    width: 100%;
    top: calc(50% - 60px);
  }
  .banner_title2{
    text-align: center;
    position: absolute;
    font-size: $Size48;
    color: white;
    width: 100%;
    top: calc(50% - 20px);
  }
}
#recruit{
  background-color: white;
  .title-second{
    font-size: $Size24;
    margin: 30px auto;
    line-height: 34px;
  }
  .email{
    font-size: $Size30;
    margin-bottom: 74px;
    span{
      color: $Color2;
    }
  }
  .jobList{
    .table-title{
      background-color: $Colorf5;
      line-height: $Size48;
      color: $indexColor999;
      padding-left: 20px;
      padding-right: 30px;
      font-size: $Size16;
      .table-right{
        width: 392px;
      }
    }
    .el-collapse-item{
      padding-left: 20px;
      padding-right: 30px;

      .jobName,.titleright{
        font-size: $Size18;
      }
      .jobName{
        width: 908px;
      }
      .titleright{
        width: 164px;
      }
      .jobAdrSal{
        margin-top: 8px;
      }
      .job-item{
        margin-bottom: 30px;
        color: $footerColor666;
        font-size: $Size16;
      }
    }
  }
}
#welfare{
  padding-top: 70px;
  .welfareBox-tips{
    margin: 20px 0 0px;
  }
  .welfareList{
    width: 881px;
    margin: 0 auto;
    .welfareItem{
      background-color: #217abc;
      width: 107px;
      height: 35px;
      border-radius: 6px;
      color: white;
      line-height: 35px;
      margin-bottom: 15px;
      margin-right: 11px;
      margin-left: 11px;
    }
  }
  .welfareBox-active{
    cursor: pointer;
    .item{
      width: 450px;
      height: 389px;
      background-color: #fff;
      margin-top: 20px;
      img{width: 100%;height: 274px;}
      .item-wz{
        padding: 15px 60px 0 28px;
        .item-tit{
          font-size: $Size20;
          margin-bottom: 10px;
        }
        .item-ms{
          font-size: $Size14;
          color: $footerColor666;
          line-height: 20px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }
  }
}

</style>

<template>
  <div id="join">
    <div class="index_banner">
      <img class="banner" src="@assets/images/join/banner.jpg" alt="">
      <div class="banner_titlBox acea-row row-center row-middle">
        <div class="banner_title1 wow fadeInUp" data-wow-delay="0.2s">加入我们</div>
        <div class="banner_title2 wow fadeInUp" data-wow-delay="0.3s">梦想在这里起航</div>
      </div>
    </div>
    <!-- 人才招聘--->
    <div id="recruit" class="BoxPadding" ref="recruit">
      <div class="width">
        <div class="title weight textCenter wow fadeInUp" data-wow-delay="0.5s">人才招聘</div>
        <div class="title-second textCenter wow fadeInUp" data-wow-delay="0.7s">
          果果慧是一个年轻又富有朝气的团队，我们崇尚活跃的组织氛围，注重平等的交流环境，
          <br/>
          不管你是初出茅庐的应届毕业生，还是经验丰富的职场精英，这里都会是你施展才华的理想舞台！
        </div>
        <div class="email textCenter weight wow fadeInUp" data-wow-delay="0.7s">简历投递邮箱:<span>hr@aifrutech.com</span></div>
        <div class="jobList wow fadeInUp" data-wow-delay="0.5s">
          <div class="table-title acea-row row-between-wrapper">
            <div>职位名称</div>
            <div class="table-right acea-row row-between-wrapper">
              <div>工作城市</div>
              <div>发布时间</div>
              <div>查看详情</div>
            </div>
          </div>
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item :name=index v-for="(item, index) in joblist" :key="index">
              <template slot="title">
                <div class="jobName weight">{{item.name}}</div>
                <div class="titleright titleright-address textCenter">{{item.address_job}}</div>
                <div class="titleright titleright-time textCenter">{{item.creatTime}}</div>
              </template>
              <div class="jobAdrSal job-item">
                <div>工作地点：{{item.company.address}}</div>
                <div>薪资范围：{{item.company.salary}}</div>
              </div>
              <div class="job-item">
                <div class="weight">岗位要求</div>
                <div class="requirement" v-for="(item1, index1) in item.requirement" :key="index1">
                  {{index1+1}}.{{item1}}
                </div>
              </div>
              <div class="job-item">
                <div class="weight">岗位职责</div>
                <div class="requirement" v-for="(item2, index2) in item.duty" :key="index2">
                  {{index2+1}}.{{item2}}
                </div>
                </div>
              <div class="job-item" v-if="item.points.length>0">
                <div class="weight">加分项</div>
                <div class="requirement" v-for="(item3, index3) in item.points" :key="index3">
                  {{index3+1}}.{{item3}}
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
    <!-- 人才招聘--->
    <!-- 福利待遇、员工活动 -->
    <div id="welfare" class="BoxPadding" ref="welfare">
      <div class="width wow fadeInUp" data-wow-delay="0.2s">
        <div class="title weight textCenter">福利待遇&员工福利</div>
        <div class="welfareBox-tips">
          <div class="welfareList acea-row row-center" v-if="welfareList1">
            <div class="welfareItem textCenter" v-for="(item1) in welfareList1" :key="item1">
              {{item1}}
            </div>
          </div>
          <div class="welfareList acea-row row-center" v-if="welfareList2">
            <div class="welfareItem textCenter" v-for="(item2) in welfareList2" :key="item2">
              {{item2}}
            </div>
          </div>
          <div class="welfareList acea-row row-center" v-if="welfareList3">
            <div class="welfareItem textCenter" v-for="(item3) in welfareList3" :key="item3">
              {{item3}}
            </div>
          </div>

        </div>
        <div class="welfareBox-active acea-row row-between-wrapper wow fadeInUp">
          <div class="item wow fadeInUp" :data-wow-delay="(parseInt(15*(index+1))/100) + 's'" v-for="(items, index) in active" :key="index" @click="bigHonorMainImg(items)">
            <img v-lazy="items.img" alt="" >
            <div class="item-wz">
              <div class="item-tit weight">{{items.title}}</div>
              <div class="item-ms">{{items.msg}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 展示放大图的容器 -->
    <el-dialog :title="dialogTitle" width="530px" center :visible.sync="dialogBigHonor">
      <img style="width:100%;margin-bottom:10px;" :src="itemdia" alt="" v-for="(itemdia,indexdia) in dialogList" :key="indexdia">
    </el-dialog>
    <!-- 福利待遇、员工活动 -->
  </div>
</template>

<script>
export default {
  name: 'join',
  components: {
  },
  data () {
    return {
      activeName: -1,
      joblist: [
        {
          name: '高级嵌入式软件工程师（图形图像相关）',
          address_job: '深圳',
          creatTime: '2022-05-23',
          company: {
            address: '深圳南山紫光信息港B301',
            salary: '20-40k'
          },
          requirement: [
            '全日制本科及以上学历，电子、计算机、通讯等相关专业；',
            '具备优秀的C或C++编程能力;',
            '熟悉Linux shell/git/gcc/make等工具；',
            '熟悉GPU体系结构，工作原理；',
            '有GPU内核驱动，用户空间图形库开发经验优先。'
          ],
          duty: [
            '负责GPU驱动开发；',
            '负责GPU用户态图形库优化；',
            '负责其他驱动，内核相关开发；',
            '负责游戏模拟器开发，性能优化。'
          ],
          points: [
            '交流能力强，善于理解对方的意图，并且能主动做出回应；',
            '乐于分享自己的技术，乐于培养新人；',
            '责任感强，热爱游戏，对复古游戏熟悉者优先。',
          ]
        },
        {
          name: '高级嵌入式软件工程师（OS开发）',
          address_job: '深圳',
          creatTime: '2022-05-23',
          company: {
            address: '深圳南山紫光信息港B301',
            salary: '30-40k'
          },
          requirement: [
            '全日制本科及以上学历，电子、计算机、通讯等相关专业；',
            '具备优秀的C或C++编程能力;',
            '熟悉Linux shell/git/gcc/make等工具；',
            '熟悉交叉编译工具链；',
            '熟练掌握嵌入式系统平台开发，如linux、rt-thread、freeRTOS、uCos等；',
            '有OpenGL，DirectX，Vulkan，shader开发经验优先;',
            '对内核优化有实际工作经验者优先。'
          ],
          duty: [
            '产品需求分析，进行产品原型，系统框架和核心模块的详细设计；',
            '负责linux或者rtos操作系统软件框架设计；',
            '负责Linux或RTOS内核优化，驱动开发；',
            '负责多媒体，图形处理软件优化移植；',
            '负责OS编译环境开发，SDK开发。'
          ],
          points: [
            '良好的沟通表达能力和团队协作能力，抗压能力；',
            '交流能力强，善于理解对方的意图，并且能主动做出回应；',
            '乐于分享自己的技术，乐于培养新人；',
            '责任感强。',
          ]
        },
        {
          name: '高级IoT嵌入式软件工程师',
          address_job: '深圳',
          creatTime: '2022-05-09',
          company: {
            address: '深圳南山紫光信息港B301',
            salary: '20-40k'
          },
          requirement: [
            '全日制本科及以上学历，电子、计算机、通讯等相关专业；',
            '3年以上嵌入式软件开发工作经验;',
            '具备优秀的C或C++编程能力；',
            '熟悉Linux shell/git/gcc/make等工具；',
            '熟悉交叉编译工具链',
            '有linux、rt-thread、freeRTOS、uCos或其他嵌入式操作系统的开发经验；',
            '熟悉NBIoT，WiFi，LTE等通信协议，有相关开发经验。'
          ],
          duty: [
            '根据IoT产品特点，研发OS内核与设备驱动，并移植到目标硬件平台；',
            '负责NBIoT，WiFi，LTE等通信相关软件，中间件开发；',
            '与硬件团队配合进行新硬件适配，bringup。'
          ],

          points: [
            '交流能力强，善于理解对方的意图，并且能主动做出回应；',
            '乐于分享自己的技术，乐于培养新人；',
            '责任感强。',
          ]
        },
        {
          name: '高级服务器应用开发工程师',
          address_job: '深圳',
          creatTime: '2022-05-09',
          company: {
            address: '深圳南山紫光信息港B301',
            salary: '20-40k'
          },
          requirement: [
            '全日制本科及以上学历，电子、计算机、通讯等相关专业；',
            '具备优秀的java spring，hibenate、nodejs编程能力；',
            '熟悉Linux shell/git/gcc/make等工具；',
            '熟悉数据库模型设计，熟练存储过程、MySQL、NoSQL技术；',
            '有java网络通讯开发者经验；',
            '有IoT类产品开发经验优先。'
          ],
          duty: [
            '负责IoT产品的云端后台管理模块，数据库开发；',
            '负责IoT产品手机端APP开发，WebApp开发。'
          ],

          points: [
            '交流能力强，善于理解对方的意图，并且能主动做出回应；',
            '乐于分享自己的技术，乐于培养新人。'
          ]
        },
        {
          name: '产品经理',
          address_job: '深圳',
          creatTime: '2022-05-09',
          company: {
            address: '深圳南山紫光信息港B301',
            salary: '20-40K'
          },
          requirement: [
            '3-5五年相关工作经验，并至少有两款产品成功上市销售的经验；',
            '喜欢游戏相关产业，并对游戏产业有一定的了解。针对游戏设备周边控制器有相关产品经验者优先考虑;',
            '对营销传播有理解，并能协助销售推进宣传工作。同时收集市场反馈；',
            '执行能力强，组织团队有计划的完成任务。并能够同时处理多个项目线，多项较差工作，协调内外部资源与沟通对接；',
            '逻辑思维能力强，目标感强，有项目全局意识，预见可能发生的问题并有协调解决问题的能力；',
            '有产品ID或硬件设计相关工作经验者优先；',
            '大专或本科学历以上，工业设计，机械设计，电子信息，计算机相关专业者优先考虑。'
          ],
          duty: [
            '根据公司规划，负责新产品开发前，对市场分析与用户需求收集；',
            '分析产品使用场景和痛点，挖掘存量市场的差异化卖点，找出用户体验提升方案；',
            '根据分析结果，撰写产品规格书和产品文档；',
            '对产品规格书与产品文档的不断整改完善，并经得起反复推销；',
            '组织内部研发资源，推动需求落地，对产品市场销售的成功性负责；',
            '深入一线与用户接触，不断收集产品反馈，分析产品数据，在产品线迭代中不断完善产品。'
          ],
          points: [] 
        },
        {
          name: '软件经理',
          address_job: '深圳',
          creatTime: '2022-04-25',
          company: {
            address: '深圳南山紫光信息港B301',
            salary: '30-40k'
          },
          requirement: [
            '电子、计算机、通讯等相关专业；',
            '5年以上嵌入式软件开发工作经验, 2年以上团队管理经验；',
            '熟悉消费类电子产品开发流程，有图形图像类产品开发经验者优先；',
            '熟练掌握嵌入式系统平台开发，如linux、rt-thread、freeRTOS、uCos等;',
            '熟悉Linux shell/git/gcc/make等工具；',
            '熟悉Linux下应用或驱动开发；',
            '熟悉敏捷开发流程者优先。',
          ],
          duty: [
            '负责软件团队搭建与管理，培养新人；',
            '负责需求评估，技术规格制定；',
            '负责软件平台架构设计；',
            '负责针对公司发展方向进行中长期的技术规划。'
          ],
          points: [
            '交流能力强，善于理解对方的意图，并且能主动做出回应；',
            '可以站在全局角度考虑软件技术方向；',
            '乐于分享自己的技术，乐于培养新人；',
            '懂得对人，对事来开展工作，而不是单纯钻研技术；',
            '有领导力，责任感强。'
          ]
        },
        {
          name: '高级结构设计工程师',
          address_job: '深圳',
          creatTime: '2022-04-25',
          company: {
            address: '深圳南山紫光信息港B301',
            salary: '15-30k'
          },
          requirement: [
            '五年以上相关工作经验；',
            '大专以上学历，本科学历优先。机械设计及其自动化、材料、模具类相关专业；',
            '掌握塑胶、塑胶模具制作工艺和表面处理工艺；或者熟练掌握金属加工、钣金、金属成型模具工艺和表面处理工艺；',
            '精通creo4.0以上版本软件，精通Aoutcad软件;',
            '懂得产品公差设计，位置公差，装配公差，活动机构公差设计;',
            '懂得产品使用寿命设计要求;',
            '至少独立成功负责或组织过三款或以上的活动结构产品开发经验，有行业经验者优先;',
            '工作态度积极有激情和责任心，能适应工作压力，有计划的完成工作，善于沟通，有良好的团队协作及敬业精神;',
            '模具行业工作3-5年以上转行者优先。'
          ],
          duty: [
            '评估产品设计方案的可行性，并能够提出满足要求的结构设计方案；',
            '能够独立完成活动机构的产品设计；',
            '针对功能以及结构需求，确定选材料；',
            '独立完成产品开模评估方案;',
            '独立完成产品组装公差的设计，并出具产品2D图纸;',
            '跟进开模过程，收集模具试产，整机试产的问题点，并改善可交付量产。'
          ],
          points: []
        },
        {
          name: '高级硬件工程师',
          address_job: '深圳',
          creatTime: '2022-04-25',
          company: {
            address: '深圳南山紫光信息港B301',
            salary: '20-30k'
          },
          requirement: [
            '电力电子以及电气等相关专业毕业，大专以上学历；',
            '5年以上消费电子类产品的硬件设计经验；',
            '精通EDA设计软件，熟悉常用开发工具与设备；',
            'EMC测试经验丰富;',
            '具有良好的沟通能力、协调推进能力，有责任感、行动力强。'
          ],
          duty: [
            '参与硬件需求分析，架构设计，详细设计，可靠性设计；',
            '进行硬件相关器件选型、原理图设计、PCB layout及制定Bom；',
            '制定EMC测试计划，并进行EMC试验；',
            '打样验证，支持试产测试;',
            '解决硬件疑难问题。'
          ],
          points: []
        },
        {
          name: '中级硬件工程师',
          address_job: '深圳',
          creatTime: '2022-04-25',
          company: {
            address: '深圳南山紫光信息港B301',
            salary: '15-25k'
          },
          requirement: [
            '专科及以上学历，电子，计算机，自动化，通信等相关专业；',
            '有3年以上消费电子类产品、智能硬件产品等的设计经验；',
            '熟练使用AD/PADS/Allegro等EDA设计工具，绘制原理图、PCB；',
            '掌握PCB设计规则，了解信号完整性、电源完整性、EMC设计基本原理;',
            '有较强的动手能力、硬件分析能力和焊接能力;',
            '具有良好的沟通能力，有责任感、执行力强，学习意愿强，抗压能力强。'
          ],
          duty: [
            '参与项目原理框架设计、元器件选型，根据原理框架图设计原理图及pcb layout，制作Bom；',
            '负责主导硬件调试及软硬件联调，提供硬件平台给软件调试软件，并提供整机给测试部门做可靠性测试、功能测试；',
            '负责主导编制加工工艺文件、测试指导书、生产相关工程文件，以及为工装治具的制作提供技术支持；',
            '负责帮助解决产品生产过程中反馈的问题点，推进项目按计划进行；',
            '负责解决产品开发过程中(包括样机开发、试产）的问题点，推动项目进入量产；',
            '负责跟进产品售前、售后的技术问题，回复客户技术问题，做技术支持；',
            '负责主导EMC摸底与认证测试，解决EMC问题。'
          ],
          points: []
        },
        {
          name: '初级硬件工程师',
          address_job: '深圳',
          creatTime: '2022-04-25',
          company: {
            address: '深圳南山紫光信息港B301',
            salary: '8-15k'
          },
          requirement: [
            '专科及以上学历，电子，计算机，自动化，通信等相关专业；',
            '2年以上消费电子类产品的硬件设计经验；',
            '熟悉AD/PADS/Allegro等原理图、PCB设计工具，了解PCB设计规则；',
            '有较强的动手能力、硬件分析能力和焊接能力;',
            '具有良好的沟通能力，有责任感、执行力强，有良好的学习态度。'
          ],
          duty: [
            '按照上级的测试计划、测试指导书和要求，执行单板/单元测试、集成测试、系统测试、可靠性测试、EMC测试 等相关测试任务。并反馈问题、输出测试报告；',
            '负责配合上级做产品硬件调试，反馈问题、输出调试报告；',
            '负责BOM表整理，按照上级要求查找替代料，输出加工BOM；',
            '负责协助跟进投板并回复板厂EQ问题，备料跟进、首件确认、加工问题跟进 等事项；',
            '负责样机的制作，跟进项目试产机器问题，及协助推动转入量产；',
            ' 配合上级制作整理生产所需工艺文件、加工工程文件，以及外部门、客户、供应商所需技术文件。'
          ],
          points: []
        },

      ],
      welfareList:[
        '年终奖金', '调薪机会', '六险一金', '年度体检', '周末双休', '带薪年假',
        '团建旅游', '节日福利', '全勤奖', '餐费补贴', '出差补贴'
      ],
      welfareList1: '',
      welfareList2: '',
      welfareList3: '',
      active: [
        {
          img: require('../assets/images/join/01.jpg'),
          title: '员工生日会',
          type: 'birthday',
          msg: '欢聚的时刻，让我们点燃生日的蜡烛，一起分享生日的甜蜜和快乐，分享生命绽放的美丽。',
          list:[
            require('../assets/images/join/birthday/01.jpg'),
            require('../assets/images/join/birthday/02.jpg')
          ]
        },
        {
          img: require('../assets/images/join/02.jpg'),
          title: '公司年会',
          type: 'annualMeeting',
          msg: '昨日的灿烂，辉煌耀眼；今天的团结，众志成城；明天的宏图，鲲鹏展翅。',
          list:[
            require('../assets/images/join/annualMeeting/01.jpg'),
            require('../assets/images/join/annualMeeting/02.jpg'),
            require('../assets/images/join/annualMeeting/03.jpg'),
            require('../assets/images/join/annualMeeting/04.jpg'),
            require('../assets/images/join/annualMeeting/05.jpg'),
          ]
        },
        {
          img: require('../assets/images/join/03.jpg'),
          title: '节假日福利',
          type: 'welfare',
          msg: '每逢重要假日，公司都会发放福利，重视对员工的人文关怀，增强员工们的归属感。',
          list:[
            require('../assets/images/join/welfare/01.jpg'),
            require('../assets/images/join/welfare/02.jpg')
          ]
        },
        {
          img: require('../assets/images/join/04.jpg'),
          title: '公司旅游',
          type: 'travel',
          msg: '始终坚持以人为本。拓展视野，增长见识，增强国际友人之间的情谊。',
          list:[
            require('../assets/images/join/travel/01.jpg'),
            require('../assets/images/join/travel/02.jpg'),
            require('../assets/images/join/travel/03.jpg'),
            require('../assets/images/join/travel/04.jpg')
          ]
        },
        {
          img: require('../assets/images/join/05.jpg'),
          title: '团建活动',
          type: 'construction',
          msg: '每周一练，放松工作压力，劳逸结合，增进感情和团队凝聚力。',
          list:[
            require('../assets/images/join/construction/01.jpg'),
            require('../assets/images/join/construction/02.jpg'),
            require('../assets/images/join/construction/03.jpg'),
            require('../assets/images/join/construction/04.jpg'),
            require('../assets/images/join/construction/05.jpg')
          ]
        },
        {
          img: require('../assets/images/join/06.jpg'),
          title: '迎新聚餐',
          type: 'dinner',
          msg: '庆祝公司队伍强大，欢聚一堂，同舟共济，携手共赢。',
          list:[
            require('../assets/images/join/dinner/01.jpg'),
            require('../assets/images/join/dinner/02.jpg'),
            require('../assets/images/join/dinner/03.jpg'),
            require('../assets/images/join/dinner/04.jpg'),
          ]
        }
      ],
      // 宽度控制
      dialogBigHonor: false,
      dialogTitle:'',
      dialogList:[],
    }
  },
  computed: {},
  created () {},
  watch: {
    $route(){
      let _that = this;
      if( _that.$route.query.type ){
        let index = _that.$route.query.type.indexOf('aifrutech');
        let link = index<0?_that.$route.query.type:_that.$route.query.type.substr(0,index);
        let offetTop = _that.$refs[link].offsetTop-66;
        window.scrollTo({ top: offetTop,left: 0,behavior: 'smooth' });
      }else{
        _that.type = ''
      }
    },
  },
  mounted(){
    new this.$WOW.WOW({offset: '100'}).init();
    let _that = this;
    let welfareList = this.welfareList;
    if(welfareList.length > 6 && welfareList.length < 13){
      _that.$set(_that, "welfareList1", welfareList.slice(0,6))
      _that.$set(_that, "welfareList2", welfareList.slice(6,(welfareList.length)))
    }else if(welfareList.length > 12){
      _that.$set(_that, "welfareList1", welfareList.slice(0,6))
      _that.$set(_that, "welfareList2", welfareList.slice(6,12))
      _that.$set(_that, "welfareList3", welfareList.slice(12,(welfareList.length)))
    }else{
      _that.$set(_that, "welfareList1", welfareList)
    }
    window.scrollTo({top: 0,left: 0});
    if(_that.$route.query.type){
      let index = _that.$route.query.type.indexOf('aifrutech');
      let link = index<0?_that.$route.query.type:_that.$route.query.type.substr(0,index);
      setTimeout(function(){
        let offetTop = _that.$refs[link].offsetTop-66;
        window.scrollTo({top: offetTop,left: 0,behavior: 'smooth'});
      },400)
    }

  },
  methods: {
    bigHonorMainImg(e){
      this.dialogList = [];
      if(e.length == 0){
        return false
      }
      this.dialogBigHonor = true;
      this.dialogList = e.list;
      this.dialogTitle = e.title;
      console.log(e)

    }
  },

}

</script>
